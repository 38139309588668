export const useConversationFragment = () => {
  return useHashToggle<{ id: number }>("inbox/:id");
};

export const useAdminSwticherFragment = () => {
  return useHashToggle({
    cs: "vyber-firmy",
    en: "company-switcher",
  });
};

export const useInboxFragment = () => {
  return useHashToggle("inbox");
};

export const useLoginEmailFragment = () => {
  return useHashToggle({
    cs: "prihlaseni/email",
    en: "login/email",
  });
};

export const useLoginFragment = () => {
  return useHashToggle({
    cs: "prihlaseni",
    en: "login",
  });
};

export const useRegisterFragment = () => {
  return useHashToggle({
    cs: "prihlaseni/registrace",
    en: "login/registration",
  });
};

export const useForgottenPasswordFragment = () => {
  return useHashToggle({
    cs: "zapomenute-heslo",
    en: "forgotten-password",
  });
};

export const useProfileFragment = () => {
  return useHashToggle({
    cs: "profil",
    en: "profile",
  });
};

export const useProfileBasicFragment = () => {
  return useHashToggle({
    cs: "profil/zakladni-udaje",
    en: "profile/basic-information",
  });
};

export const useProfileAboutFragment = () => {
  return useHashToggle({
    cs: "profil/o-mne",
    en: "profile/about-me",
  });
};

export const useProfilePreferencesFragment = () => {
  return useHashToggle({
    cs: "profil/jakou-praci-hledam",
    en: "profile/job-preferences",
  });
};

export const useProfileSkillsFragment = () => {
  return useHashToggle({
    cs: "profil/co-umim",
    en: "profile/skills",
  });
};

export const useProfileSettingsFragment = () => {
  return useHashToggle({
    cs: "profil/nastaveni",
    en: "profile/settings",
  });
};

export const useProfileGdprFragment = () => {
  return useHashToggle({
    cs: "profil/pravidla-soukromi",
    en: "profile/privacy-policy",
  });
};

export const useProfileNotificationsFragment = (nested: boolean) => {
  return useHashToggle({
    cs: nested ? "profil/notifikace" : "notifikace",
    en: nested ? "profile/notifications" : "notifications",
  });
};

export const useSupplicationFragment = () => {
  return useHashToggle({
    cs: "interest",
    en: "interest",
  });
};

export const useApplicationFragment = () => {
  return useHashToggle({
    cs: "application",
    en: "application",
  });
};

export const useOfferFeedbackFragment = () => {
  return useHashToggle({
    cs: "feedback",
    en: "feedback",
  });
};

export const useMarketplaceApplicationFragment = () => {
  return useHashToggle({
    cs: "application",
    en: "application",
  });
};

export const useApplicationSuccessFragment = () => {
  return useHashToggle<{ hash: string }>({
    cs: "zajem-odeslan/:hash",
    en: "interest-sent/:hash",
  });
};

export const useResetPasswordFragment = () => {
  return useHashToggle<{ hash: string }>({
    cs: "obnova-hesla/:hash",
    en: "reset-password/:hash",
  });
};

export const useChangePasswordFragment = () => {
  return useHashToggle<{ hash: string }>({
    cs: "profil/nastaveni/zmena-hesla",
    en: "profile/settings/change-password",
  });
};

export const useEmailSettingsFragment = () => {
  return useHashToggle<{ hash: string }>({
    cs: "profil/nastaveni/email",
    en: "profile/settings/email",
  });
};

export const useTwoFAActivationFragment = () => {
  return useHashToggle<{ hash: string }>({
    cs: "profil/nastaveni/2fa-aktivace",
    en: "profile/settings/2fa-activation",
  });
};

export const useTwoFADeactivationFragment = () => {
  return useHashToggle<{ hash: string }>({
    cs: "profil/nastaveni/2fa-deaktivace",
    en: "profile/settings/2fa-deactivation",
  });
};

export const useTwoFALoginFragment = () => {
  return useHashToggle({
    cs: "2fa",
    en: "2fa",
  });
};

export const useMoreFragment = () => {
  return useHashToggle<{ hash: string }>({
    cs: "vice",
    en: "more",
  });
};

export const useEventOrderFragment = () => {
  return useHashToggle("event-order");
};

export const useEventOrderSuccessFragment = () => {
  return useHashToggle("event-order/success");
};

export const useEventBillingEditFragment = () => {
  return useHashToggle("event-order/billing-edit");
};
